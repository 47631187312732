var render = function render(){var _vm=this,_c=_vm._self._c;return _c('grid-view',{attrs:{"title":"Ключи","filter":"","url-update":"/code/update/:id","store-module":"code","headers":[
        { text: 'ID', value: 'id', width: 1 },
        { text: 'Активность', value: 'active', width: 1, sortable: false },
        { text: 'Ключ', value: 'value', sortable: false },
        { text: 'Тип лицензии', value: 'license_type.name', sortable: false },
        { text: 'Источник', value: 'source', width: 1, sortable: false },
        { text: '', value: 'edit', width: 1, sortable: false },
        { text: '', value: 'remove', width: 1, sortable: false }
    ],"format":_vm.formatItems,"checkDeletePermission":(item) => {
        return false;
    }},scopedSlots:_vm._u([{key:"filter",fn:function({ filter }){return [_c('v-row',[_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[{
                    text: 'Все',
                    value: null
                  },{
                    text: 'Нет',
                    value: false
                  },{
                    text: 'Да',
                    value: true
                  }],"label":"Активность"},model:{value:(filter.active),callback:function ($$v) {_vm.$set(filter, "active", $$v)},expression:"filter.active"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","autocomplete":"new-email","label":"ID","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.id),callback:function ($$v) {_vm.$set(filter, "id", $$v)},expression:"filter.id"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-text-field',{attrs:{"dense":"","autocomplete":"new-email","label":"Название","type":"text","placeholder":" ","color":_vm.$const.color.primary},model:{value:(filter.value),callback:function ($$v) {_vm.$set(filter, "value", $$v)},expression:"filter.value"}})],1),_c('v-col',{attrs:{"lg":"6","sm":"12"}},[_c('v-select',{attrs:{"dense":"","color":_vm.$const.color.primary,"items":[
                    {
                        text: 'Все',
                        value: null
                    },
                    ..._vm.license_type
                  ],"label":"Тип лицензии"},model:{value:(filter.license_type_id),callback:function ($$v) {_vm.$set(filter, "license_type_id", $$v)},expression:"filter.license_type_id"}})],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }